import mergeOperations from 'packages/framework/util/shallowMerge';
import DEFAULT_OPERATIONS from '../queries/rmaSettings.gql';
import { useQuery } from '@apollo/client';
import { useUserState } from 'packages/framework/context/user';

export const useRmaSettings = (props = {}) => {
  const operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  const { getRmaSettings, getRmaReturns } = operations;
  const { isSignedIn } = useUserState();

  const { data, error } = useQuery(getRmaSettings, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const { data: returnsData } = useQuery(getRmaReturns, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !isSignedIn
  });

  const { getAmRmaSettings } = data || {};
  const { getAmRmaReturnsForCurrentCustomer: rmaReturns } = returnsData || {};

  return {
    ...getAmRmaSettings,
    rmaReturns,
    error
  };
};
