import React, { createContext, useContext } from 'react';
import { useRmaSettings } from './talons/useRmaSettings';

const amRmaContext = createContext(null);
const { Provider } = amRmaContext;

const AmRmaContextProvider = props => {
  const { children } = props;

  const contextValue = useRmaSettings();
  const { error } = contextValue;

  if (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    }

    console.log('Check if Amasty modules has been installed!');
  }

  return <Provider value={contextValue}>{children}</Provider>;
};

export default AmRmaContextProvider;

export const useAmRmaContext = () => useContext(amRmaContext);
