import { gql } from '@apollo/client';

const AmRmaRequestFragment = gql`
  fragment AmRmaRequestFragment on AmRmaReturnRequest {
    store_id
    order_id
    request_id
    created_at
    modified_at
    status {
      status_id
      state
      label
      title
      color
    }
    customer_id
    customer_name
    url_hash
    manager_id
    custom_fields
    rating
    rating_comment
    note
    request_items {
      request_item_id
      request_id
      order_item_id
      qty
      request_qty
      reason_id
      condition_id
      resolution_id
      item_status
      product_url_key
    }
    tracking_numbers {
      tracking_id
      request_id
      tracking_code
      tracking_number
      is_customer
    }
    shipping_label
  }
`;

export const GET_RMA_SETTINGS = gql`
  query getAmRmaSettings {
    getAmRmaSettings {
      isEnabled
      isChatEnabled
      isReturnPolicyEnabled
      isAskForFeedback
      isShowAdministratorContact
      urlPrefix
      maxAttachmentSize
      policyUrl
      administratorPhoneNumber
      administratorEmail
      customFieldsBlockTitle
      shippingCarriers {
        code
        label
      }
      customFields {
        code
        title
      }
      allReasons {
        reason_id
        label
        payer
        position
      }
      allConditions {
        condition_id
        label
        position
      }
      allResolutions {
        resolution_id
        label
        status
        position
      }
    }
  }
`;

export const GET_ORDER_LIST = gql`
  query getAmRmaOrderList {
    getAmRmaOrderList {
      order_id
      label
    }
  }
`;

export const GET_RMA_POLICY = gql`
  query getAmRmaPolicy {
    getAmRmaPolicy {
      title
      content
    }
  }
`;

export const GET_RMA_INSTRUCTION = gql`
  query getAmRmaReturnInstruction($status_id: Int!) {
    getAmRmaReturnInstruction(status_id: $status_id)
  }
`;

export const GET_RMA_RETURNS = gql`
  query getAmRmaReturnsForCurrentCustomer {
    getAmRmaReturnsForCurrentCustomer {
      ...AmRmaRequestFragment
    }
  }
  ${AmRmaRequestFragment}
`;

export const CLOSE_RMA = gql`
  mutation closeAmRmaCustomerReturnRequest($id: Int!) {
    closeAmRmaCustomerReturnRequest(id: $id) {
      error
      message
    }
  }
`;

export const RATE_RMA = gql`
  mutation rateAmRmaReturnRequest(
    $hash: String!
    $rating: Int!
    $comment: String
  ) {
    rateAmRmaReturnRequest(
      input: { hash: $hash, rating: $rating, comment: $comment }
    ) {
      error
      message
    }
  }
`;

export const PLACE_RMA = gql`
  mutation placeAmRmaCustomerReturnRequest(
    $order_id: Int!
    $policy_allowed: Int
    $comment: String
    $return_items: [PlaceAmRmaReturnRequestItemInput]!
    $custom_fields: [AmRmaCustomFieldInput]
    $files: [SendAmRmaMessageFileInput] 
    
  ) {
    placeAmRmaCustomerReturnRequest(
      input: {
        order_id: $order_id
        comment: $comment
        policy_allowed: $policy_allowed
        return_items: $return_items
        custom_fields: $custom_fields
        files: $files
      }
    ) {
      ...AmRmaRequestFragment
    }
  }
  ${AmRmaRequestFragment}
`;

export const GET_RMA_RETURN_BY_ID = gql`
  query getAmRmaReturnById($request_id: Int!) {
    getAmRmaReturnById(request_id: $request_id) {
      ...AmRmaRequestFragment
    }
  }
  ${AmRmaRequestFragment}
`;

export default {
  getRmaSettings: GET_RMA_SETTINGS,
  getRmaReturns: GET_RMA_RETURNS,
  getOrderList: GET_ORDER_LIST,
  getRmaPolicy: GET_RMA_POLICY,
  getRmaInstruction: GET_RMA_INSTRUCTION,
  closeRmaMutation: CLOSE_RMA,
  rateRmaMutation: RATE_RMA,
  getRmaById: GET_RMA_RETURN_BY_ID,
  placeRmaMutation: PLACE_RMA
};
