export const URLS = {
  SEARCHURL: '/search',
  SEARCHURLPARAMS: '/search?query=',
};

export const AVAILABLE_ITEMS_CODES = ['popular_searches', 'recent_searches'];

export const RMA_STATE = {
  PENDING: 0,
  AUTHORIZED: 1,
  RECEIVED: 2,
  RESOLVED: 3,
  CANCELED: 4
};
